import React, { useState, useEffect } from "react";
import axios from "axios";
import { BaseUrl, websocketConnectionUrl } from "../../../static/BaseUrl.js";
import {
  DatePicker,
  Table,
  Select,
  message,
  Modal,
  Button,
  Form,
  Input,
} from "antd";
const Option = Select.Option;

const Screen = () => {
  const [wsGlobal, setWsGlobal] = React.useState();
  const [loading, setLoading] = useState(false);
  const [charegrStatusesArray, setCharegrStatusArray] = useState();
  const [rawResponseData, setRawResponseData] = useState();
  const [responseMessage, setResponseMessage] = useState();
  const [filterSet, setfilterSet] = useState(false);

  const [selectStatus, setSelectedStatus] = useState();
  const [triggerMessageModalOpen, setTriggerMessageModalOpen] = useState();
  const [users, setUsers] = useState();
  const [selectedUser, setSelectedUser] = useState();

  const [localLists, setLocalLists] = useState([]);
  const [unchangedLocalist, setUnchangedLocalList] = useState([]);

  const [selectedMobile, setSelectedMobile] = useState();
  const [selectedChargerForFilter, setSelectedChargerForFilter] = useState();

  const [tableShownData, setTableShownData] = useState([]);

  const [editStatusModelOpen, setEditStatusModelOpen] = useState(false);

  const [updatedStatus, setUpdatedStatus] = useState();

  const [updatedList, setUpdatedList] = useState();

  useEffect(() => {
    getUsers();
    getLocalLists();
  }, []);

  const getLocalLists = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/locallists`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setLocalLists(res?.data?.localLists);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatLocalListsData = async (localLists) => {
    try {
      const resultedArray = [];
      localLists?.map((localList) => {
        let chargerName = localList?.chargerName;
        localList?.localList?.map((list) => {
          let user;
          users?.map((user1) => {
            if (user1?.idTag === list?.idTag) {
              user = user1;
            }
          });
          let data = {
            idTag: list?.idTag,
            status: list?.status,
            chargerName,
            userName: user?.name,
            phone: user?.phone,
            walletAmount: user?.walletAmount,
          };
          resultedArray.push(data);
        });
      });
      setTableShownData(resultedArray);
      setUnchangedLocalList(resultedArray);
    } catch (error) {
      console.log(error);
    }
  };

  const filterData = () => {
    try {
      if (selectStatus && selectedMobile && selectedChargerForFilter) {
        setfilterSet(true);
        const array = unchangedLocalist;
        const resultArray = [];
        array?.map((list) => {
          if (
            list?.phone === selectedMobile &&
            list?.chargerName === selectedChargerForFilter &&
            list?.status === selectStatus
          ) {
            resultArray.push(list);
          }
        });
        setTableShownData(resultArray);
        setLoading(false);
      } else if (selectStatus && selectedMobile) {
        setfilterSet(true);
        const array = unchangedLocalist;
        const resultArray = [];
        array?.map((list) => {
          if (list?.phone === selectedMobile && list?.status === selectStatus) {
            resultArray.push(list);
          }
        });
        setTableShownData(resultArray);
        setLoading(false);
      } else if (selectedChargerForFilter && selectedMobile) {
        setfilterSet(true);
        const array = unchangedLocalist;
        const resultArray = [];
        array?.map((list) => {
          if (
            list?.phone === selectedMobile &&
            list?.chargerName === selectedChargerForFilter
          ) {
            resultArray.push(list);
          }
        });
        setTableShownData(resultArray);
        setLoading(false);
      } else if (selectedChargerForFilter) {
        setfilterSet(true);
        const array = unchangedLocalist;
        const resultArray = [];
        array?.map((list) => {
          if (list?.chargerName === selectedChargerForFilter) {
            resultArray.push(list);
          }
        });
        setTableShownData(resultArray);
        setLoading(false);
      } else if (selectStatus) {
        setfilterSet(true);
        const array = unchangedLocalist;
        const resultArray = [];
        array?.map((list) => {
          if (list?.status === selectStatus) {
            resultArray.push(list);
          }
        });
        setTableShownData(resultArray);
        setLoading(false);
      } else if (selectedMobile) {
        setfilterSet(true);
        const array = unchangedLocalist;
        const resultArray = [];
        array?.map((list) => {
          if (list?.phone === selectedMobile) {
            resultArray.push(list);
          }
        });
        setTableShownData(resultArray);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "userName",
    },
    {
      title: "Phone",
      dataIndex: `phone`,
    },
    {
      title: "IdTag",
      dataIndex: `idTag`,
    },
    {
      title: "Charger Name",
      dataIndex: "chargerName",
    },
    {
      title: "Wallet Amount",
      dataIndex: "walletAmount",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.walletAmount - b.walletAmount,
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "  Action",
      fixed: "right",
      // width: 100,
      render: (text, record) => (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <a
            className="m-5"
            onClick={() => {
              setSelectedUser(record);
              setEditStatusModelOpen(true);
            }}
          >
            <i
              style={{ color: "blue" }}
              class="fa-regular fa-pen-to-square"
            ></i>
          </a>
        </div>
      ),
    },
  ];

  // getUserDetails
  const getUsers = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/get/users`, {
        token: localStorage.getItem("token"),
      });
      if (res?.data?.success) {
        setUsers(res?.data?.users);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    formatLocalListsData(localLists);
  }, [users, localLists]);

  const [localListSend, setLocalListSend] = useState(false);
  const [formattedChargerLocalList, setformattedChargerLocalList] = useState();

  const handleSendRequest = async (socket) => {
    try {
      setLocalListSend(true);
      setTimeout(() => {
        setLocalListSend(false);
      }, 5000);
      setTriggerMessageModalOpen(true);
      charegrStatusesArray?.map((charger) => {
        // console.log(charger);
        if (
          charger[0] === selectedUser?.chargerName &&
          (charger[1] === "Unavailable" || charger[1] === undefined)
        ) {
          setTriggerMessageModalOpen(false);
          return message.error("Charger not connected to internet");
        } 
      });

      let localList = localLists;
      localList?.map((list) => {
        try {
          if (list?.chargerName === selectedUser?.chargerName) {
            list?.localList?.map((List) => {
              if (List?.idTag === selectedUser?.idTag) {
                List.status = updatedStatus;
              }
            });
            setUpdatedList(list);

            let fcll = [];
            list?.localList?.map((List) => {
              let object = {
                items: {
                  idTag: List.idTag,
                  idTagInfo: {
                    status: List.status,
                  },
                },
              };
              fcll.push(object);
            });
            setformattedChargerLocalList(fcll);
          }
        } catch (error) {
          console.log(error);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (localListSend) {
      let sendData = {
        From: "BD8746G645VWU83",
        To: `${selectedUser?.chargerName}`,
        message: [
          2,
          "SendLocalListRequest",
          "SendLocalList",
          {
            listVersion: 1,
            localAuthorizationList: formattedChargerLocalList,
            updateType: "Full",
          },
        ],
      };
      console.log(JSON.stringify(sendData));
      wsGlobal.send(JSON.stringify(sendData));
    }
  }, [formattedChargerLocalList]);

  const handleUpdateLocalListInDataBase = async () => {
    try {
      const res = await axios.post(`${BaseUrl}/update/locallist`, {
        token: localStorage.getItem("token"),
        idTag: selectedUser?.idTag,
        status: updatedStatus,
        chargerName: updatedList?.chargerName,
      });
      if (res?.data?.success) {
        setTriggerMessageModalOpen(false);
        message.success("Updated Successfully");
        getLocalLists();
      } else {
        message.warning("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const charger = rawResponseData && rawResponseData.split("*")[1]?.trim();
    if (charger === updatedList?.chargerName) {
      console.log(rawResponseData);
      if (
        rawResponseData &&
        rawResponseData.split("*")[2]?.split('"')[3] === "status" &&
        rawResponseData.split("*")[2]?.split('"')[1] === "SendLocalListRequest"
      ) {
        const message =
          "Status : " + rawResponseData.split("*")[2]?.split('"')[5];
        setResponseMessage(message);
        if (rawResponseData.split("*")[2]?.split('"')[5] === "Accepted") {
          handleUpdateLocalListInDataBase();
        }
      }
    }
  }, [rawResponseData]);

  useEffect(() => {
    var ws = new WebSocket(websocketConnectionUrl);
    ws.onopen = () => {
      let sendData = {
        From: "AdminDashboard",
      };
      ws.send(sendData);

      var refresh = true;
      setInterval(() => {
        refresh = true;
      }, 5000);
      ws.onmessage = (e) => {
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const array = JSON.parse(arrayString);
            // const mapFromJSON = array.reduce((map, [key, value]) => {
            //   map.set(key, value);
            //   return map;
            // }, new Map());
            setCharegrStatusArray(array);
          } else {
            setRawResponseData(e.data);
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <Modal
        centered
        open={triggerMessageModalOpen}
        // onCancel={() => {
        //   setTriggerMessageModalOpen(false);
        //   setResponseMessage("Waiting for response...");
        // }}
        footer={[
          null,
          // <Button
          //   onClick={() => {
          //     setTriggerMessageModalOpen(false);
          //     setResponseMessage("Waiting for response...");
          //   }}
          // >
          //   Close
          // </Button>,
          // <Button onClick={() => {setUnlockConnectorModalOpen(false) ; handleUnlockConnector()}}>Unlock</Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">Send local list</p>
        <p className="  text-center p-4 pt-8">
          {responseMessage ? responseMessage : "Waiting for response..."}
        </p>
        <p className="text-center p-4 pt-4">
          {" "}
          {responseMessage
            ? "Wait for sometime for update status in backend"
            : ""}
        </p>
      </Modal>

      {/* Edit User IdTag Status */}
      <Modal
        centered
        open={editStatusModelOpen}
        onCancel={() => {
          setEditStatusModelOpen(false);
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setEditStatusModelOpen(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            onClick={() => {
              setEditStatusModelOpen(false);
              handleSendRequest(wsGlobal);
            }}
          >
            Update
          </Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">
          Update {selectedUser?.userName} idTag's status
        </p>
        <div style={{ marginLeft: 10, marginTop: 20 }}>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select status"
            optionFilterProp="children"
            defaultValue={selectedChargerForFilter}
            onChange={(value) => setUpdatedStatus(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option style={{ fontSize: 12 }} value={"Accepted"}>
              Accepted
            </Option>
            <Option style={{ fontSize: 12 }} value={"Blocked"}>
              Blocked
            </Option>
            <Option style={{ fontSize: 12 }} value={"Expired"}>
              Expired
            </Option>
            <Option style={{ fontSize: 12 }} value={"Invalid"}>
              Invalid
            </Option>
            <Option style={{ fontSize: 12 }} value={"ConcurrentTx"}>
              ConcurrentTx
            </Option>
          </Select>
        </div>
      </Modal>

      <p className="font-semibold text-xl mb-1">
        CMS Operation : Send Local List
      </p>

      {/* Filter Data */}
      <div className="mt-10 flex flex-col flex-wrap sm:flex-row justify-between">
        {/* Select User */}
        <div className="flex ml-2">
          <p style={{ marginTop: 2 }}>User Details</p>
          <Select
            className="ml-4"
            showSearch
            defaultValue={selectedMobile}
            style={{ width: 300 }}
            placeholder="Select User"
            optionFilterProp="children"
            onChange={(value) => setSelectedMobile(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option style={{ fontSize: 12 }} value={undefined}>
              All users
            </Option>
            {users &&
              users?.map((user) => {
                return (
                  <Option style={{ fontSize: 12 }} value={user?.phone}>
                    {"🧑🏻‍💼 " + user?.name + " 📞 " + user?.phone?.toLowerCase()}
                  </Option>
                );
              })}
          </Select>
        </div>

        {/* Select Charger */}
        <div className="flex ml-2">
          <p style={{ marginTop: 2 }}>Charger</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select Charger"
            optionFilterProp="children"
            defaultValue={selectedChargerForFilter}
            onChange={(value) => setSelectedChargerForFilter(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option style={{ fontSize: 12 }} value={undefined}>
              All chargers
            </Option>
            {localLists &&
              localLists?.map((list) => {
                return (
                  <Option style={{ fontSize: 12 }} value={list?.chargerName}>
                    {list?.chargerName}
                  </Option>
                );
              })}
          </Select>
        </div>

        {/* Select Status */}
        <div className="flex ml-2">
          <p style={{ marginTop: 2 }}>Status</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 200 }}
            placeholder="Select status"
            optionFilterProp="children"
            defaultValue={selectedChargerForFilter}
            onChange={(value) => setSelectedStatus(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option style={{ fontSize: 12 }} value={"Accepted"}>
              Accepted
            </Option>
            <Option style={{ fontSize: 12 }} value={"Blocked"}>
              Blocked
            </Option>
            <Option style={{ fontSize: 12 }} value={"Expired"}>
              Expired
            </Option>
            <Option style={{ fontSize: 12 }} value={"Invalid"}>
              Invalid
            </Option>
            <Option style={{ fontSize: 12 }} value={"ConcurrentTx"}>
              ConcurrentTx
            </Option>
          </Select>
        </div>

        {/* For location Filter */}
        {/* <div className="flex ml-2">
          <p style={{ marginTop: 2 }}>Select location</p>
          <Select
            className="ml-4"
            showSearch
            style={{ width: 300 }}
            placeholder="Select location"
            optionFilterProp="children"
            onChange={(value) => setSelectedLocation(value)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {locations &&
              locations?.map((location) => {
                return (
                  <Option style={{ fontSize: 12 }} value={location?.address}>
                    {location?.address}
                  </Option>
                );
              })}
          </Select>
        </div> */}
        <div className="flex justify-center">
          <a
            onClick={() => filterData()}
            className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
          >
            Filter Data
          </a>
        </div>
      </div>

      <div className="flex justify-between mt-3">
        <a
          onClick={() => {
            getUsers();
            getLocalLists();
            setfilterSet(false);
          }}
          className="text-center pr-2 pl-2 pt-1 pb-1 border rounded-md bg-slate-500 text-white"
        >
          Refresh
        </a>
        {filterSet ? (
          <a
            onClick={() => {
              getUsers();
              setfilterSet(false);
            }}
            className="mr-2 mt-2"
            style={{ fontSize: 13 }}
          >
            Clear Filters
          </a>
        ) : (
          ""
        )}
      </div>

      {/* <div className="mt-24 flex justify-center">
        <Select
          style={{ width: 200, marginLeft: 20, marginRight: 20 }}
          showSearch
          className="shadow-xl"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          placeholder={"Select Charger"}
          onChange={(value) => setSelectedCharger(value)}
        >
          {charegrStatusesArray &&
            charegrStatusesArray?.map((charger) => {
              if (charger?.status !== "Unavailable") {
                return (
                  <option value={charger?.chargerName}>
                    {charger?.chargerName}
                  </option>
                );
              }
            })}
        </Select>

        {selectedCharger ? (
          <Select
            style={{ width: 300, marginLeft: 20, marginRight: 20 }}
            className="shadow-xl"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder={"Select User"}
            onChange={(value) => setSelectedUser(value)}
          >
            {users &&
              users?.map((user) => {
                return (
                  <option value={JSON.stringify(user)}>
                    {"🧑🏻‍💼 " + user?.name + " 📞 " + user?.phone?.toLowerCase()}
                  </option>
                );
              })}
          </Select>
        ) : (
          ""
        )}

        {selectedUser ? (
          <Select
            style={{ width: 200, marginLeft: 20, marginRight: 20 }}
            className="shadow-xl"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
            placeholder={"Select status"}
            onChange={(value) => setSelectedStatus(value)}
          >
            <option value="Accepted">Accepted</option>
            <option value="Blocked">Blocked</option>
            <option value="Expired">Expired</option>
            <option value="Invalid">Invalid</option>
            <option value="ConcurrentTx">BloConcurrentTxcked</option>
          </Select>
        ) : (
          ""
        )}
      </div>
      {selectStatus ? (
        <div className="flex justify-center mt-10">
          <Button
            style={{ backgroundColor: "blue", color: "white" }}
            className="shadow-xl"
            primary
            onClick={() => {
              handleSendRequest(wsGlobal);
            }}
          >
            Send Request
          </Button>
        </div>
      ) : (
        ""
      )} */}

      {/* Showing Users */}
      <div className="mt-4 overflow-auto">
        <Table
          columns={columns}
          dataSource={tableShownData && tableShownData}
        />
      </div>
    </div>
  );
};

export default Screen;
