import React, { useState, useEffect } from "react";
import { Button, Select, message, Modal } from "antd";
import { websocketConnectionUrl } from "../../../static/BaseUrl";

const Screen = () => {
  const [wsGlobal, setWsGlobal] = React.useState();
  const [charegrStatusesArray, setCharegrStatusArray] = useState();
  const [rawResponseData, setRawResponseData] = useState();
  const [responseMessage, setResponseMessage] = useState();

  const [selectedCharger, setSelectedCharger] = useState();
  const [triggerMessageModalOpen, setTriggerMessageModalOpen] = useState();

  const handleSendRequest = async (socket) => {
    try {
      setTriggerMessageModalOpen(true);
      let sendData = {
        From: "BD8746G645VWU83",
        To: `${selectedCharger}`,
        message: [2, "GetLocalListVersion", "GetLocalListVersion", {}],
      };
      await socket.send(JSON.stringify(sendData));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const charger = rawResponseData && rawResponseData.split("*")[1]?.trim();
    if (charger === selectedCharger) {
      if (
        rawResponseData &&
        rawResponseData.split("*")[2]?.split('"')[3] === "GetLocalListVersion"
      ) {
        const message = rawResponseData?.split("*")[2];
        setResponseMessage(message);
      }
      // else if (
      //   rawResponseData &&
      //   rawResponseData.split("*")[2]?.split('"')[3] === 'GetLocalListVersion'
      // ) {
      //   setResponseMessage(rawResponseData.split("*")[2]);
      // }
    }
  }, [rawResponseData]);

  useEffect(() => {
    var ws = new WebSocket(websocketConnectionUrl);
    ws.onopen = () => {
      let sendData = {
        From: "BD8746G645VWU83",
      };
      ws.send(sendData);

      ws.onmessage = (e) => {
        var refresh = true;
        setInterval(() => {
          refresh = true;
        }, 5000);
        try {
          if (e.data.split("*")[0] === "StatusNotification ") {
            const arrayString = e.data.split("*")[1];
            const chargersMap = new Map();
            const array = JSON.parse(arrayString);
            setCharegrStatusArray(array);
            array.map((charger) => {
              return chargersMap.set(charger[0], charger[1]);
            });
            if (refresh === true) {
              refresh = false;
              // setChargerStatus(chargersMap);
            }
          } else {
            setRawResponseData(e.data);
          }
        } catch (error) {}
      };

      ws.onclose = (e) => {
        ws.close();
      };
    };
    setWsGlobal(ws);
  }, []);

  return (
    <div>
      <Modal
        // title="Confirm"
        centered
        open={triggerMessageModalOpen}
        onCancel={() => {
          setTriggerMessageModalOpen(false);
          setResponseMessage("Waiting for response...");
        }}
        footer={[
          null,
          <Button
            onClick={() => {
              setTriggerMessageModalOpen(false);
              setResponseMessage("Waiting for response...");
            }}
          >
            Close
          </Button>,
          // <Button onClick={() => {setUnlockConnectorModalOpen(false) ; handleUnlockConnector()}}>Unlock</Button>,
        ]}
      >
        <p className=" font-bold text-center p-4 pt-8">
          Get local list version
        </p>
        <p className="  text-center p-4 pt-8">
          {responseMessage ? responseMessage : "Waiting for response..."}
        </p>
        {/* <p className="font-bold text-center p-4">
          {selectedLocation && selectedLocation?.captiveCode}
        </p> */}
      </Modal>

      <p className="font-semibold text-xl mb-1">
        CMS Operation : Get Local List
      </p>
      <p className="text-slate-600">
        In order to support synchronisation of the Local Authorization List,
        Central System can request a Charge Point for the version number of the
        Local Authorization List. The Central System SHALL send a
        GetLocalListVersion.req PDU to request this value. Upon receipt of a
        GetLocalListVersion.req PDU Charge Point SHALL respond with a
        GetLocalListVersion.conf PDU containing the version number of its Local
        Authorization List. A version number of 0 (zero) SHALL be used to
        indicate that the local authorization list is empty, and a version
        number of -1 SHALL be used to indicate that the Charge Point does not
        support Local Authorization Lists.
      </p>

      <div className="mt-24 flex justify-center">
        <Select
          style={{ width: 200, marginLeft: 20, marginRight: 20 }}
          showSearch
          className="shadow-xl"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          placeholder={"Select Charger"}
          onChange={(value) => setSelectedCharger(value)}
        >
          {charegrStatusesArray &&
            charegrStatusesArray?.map((charger) => {
              if (charger[1] !== "Unavailable") {
                return <option value={charger[0]}>{charger[0]}</option>;
              }
            })}
        </Select>
      </div>

      {selectedCharger ? (
        <div className="flex justify-center mt-10">
          <Button
            style={{ backgroundColor: "blue", color: "white" }}
            className="shadow-xl"
            primary
            onClick={() => {
              handleSendRequest(wsGlobal);
            }}
          >
            Send Request
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Screen;
